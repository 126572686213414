<script>
import BaseListing from './Listing'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      headerTitle: { title: 'Role: Ford', description: 'Lista' },
      service: this.$route.meta.acl.service,
      actionShowUrlName: '',
      gridName: 'grid_role',
      gridActions: [],
      gridColumns: this.$route.meta.columns
    }
  },
  created () {
    this.getInitGridActions()
    this.getInitActionShowUrlName()
  },
  methods: {
    getInitGridActions () {
      this.gridActions = [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'eye', acl: { service: this.service, action: 'get_role' } }
      ]
    },
    getInitActionShowUrlName () {
      this.actionShowUrlName = `${this.service}_role_details`
    }
  }
}
</script>
